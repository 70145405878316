.btn, .sp-container button {
  display: inline-block;
  font-weight: 400;
  color: $default-color;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 0.78rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px ;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn, .sp-container button {
    transition: none;
  }
}

.btn:hover, .sp-container button:hover {
  color: $default-color;
  text-decoration: none;
}

.btn:focus, .sp-container button:focus, .btn.focus, .sp-container button.focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled, .sp-container button.disabled, .btn:disabled, .sp-container button:disabled {
  opacity: .65;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary-border;

  &:hover {
    color: $white;
    background-color:$primary-hover !important;
    border-color: $primary-border;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.15rem rgba(119, 210, 191, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $primary!important;
    border-color: $primary  !important;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow:0 0 0 0.2rem $primary-05;
  }
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 210, 191, 0.2);
}

.btn-secondary {
  color: $white;
  background-color: $secondary;
  border-color: #f5567b;

  &:hover {
    color: $white;
    background-color: #f33461;
    border-color: rgb(245, 86, 142);
  }

  &:focus, &.focus {
    box-shadow:0 0 0 0.2rem #f33e68;
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $secondary;
    border-color: $secondary;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $secondary;
      border-color: #f5567b;
    }
  }
}

.show > .btn-secondary.dropdown-toggle {
  color: $white;
  background-color: $secondary;
  border-color: $secondary;
}

.btn-secondary:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow:0 0 0 0.1rem #f74f75;
  }
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 79, 117, 0.5);
}

.btn-success {
  color: $white;
  background-color: $success;
  border-color: $success;

  &:hover {
    color: $white;
    background-color: #068f78 ;
    border-color: #1a9c86;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $success;
    border-color: $success;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $success;
      border-color: $success;
    }
  }
}

.show > .btn-success.dropdown-toggle {
  color: $white;
  background-color: $success;
  border-color: $success;
}

.btn-success:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
  }
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
}

.btn-info {
  color: $white !important;
  background-color: $info;
  border-color: rgb(108, 193, 226);

  &:hover {
    color: $white !important;
    background-color: rgb(72, 179, 221);
    border-color: $info;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $info;
    border-color: $info;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $info;
      border-color: $info;
    }
  }
}

.show > .btn-info.dropdown-toggle {
  color: $white;
  background-color: $info;
  border-color: $info;
}

.btn-info:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
  }
}

.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.btn-warning {
  color: $white;
  background-color: $warning;
  border-color: #f6c06f;

  &:hover {
    color: $white;
    background-color: #f8ab37;
    border-color: #f6ac3c;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $warning !important;
    border-color: $warning !important;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $warning;
      border-color: $warning;
    }
  }
}

.show > .btn-warning.dropdown-toggle {
  color: $white;
  background-color: $warning;
  border-color: $warning;
}

.btn-warning:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
  }
}

.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
}

.btn-danger {
  color: $white !important;
  background-color: $danger;
  border-color: #f35959;

  &:hover {
    color: $white;
    background-color: #f12d2d;
    border-color: #f13939;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.2);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $danger;
    border-color: $danger;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $danger;
      border-color: $danger;
    }
  }
}

.show > .btn-danger.dropdown-toggle {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}

.btn-danger:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.5);
  }
}

.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.5);
}

.btn-teal {
  color: $white;
  background-color: $teal;
  border-color: #05d6d6;

  &:hover {
    color: $white;
    background-color: #04bfbf;
    border-color: #04bfbf;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 192, 192, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $teal;
    border-color: $teal;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $teal;
      border-color: $teal;
    }
  }
}

.show > .btn-teal.dropdown-toggle {
  color: $white;
  background-color: $teal;
  border-color: $teal;
}

.btn-teal:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 192, 192, 0.5);
  }
}

.show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 192, 192, 0.5);
}
.btn-white {
  color: $gray-900;
  background-color: $white;
  border-color: #e9e9ff82;

  &:hover {
    color: $gray-900;
    background-color: $white;
    border-color: #d5d9e4;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
    background-color: $white !important;
    border-color: #ededf5 !important;
  }

  &.disabled, &:disabled {
    color: $gray-900;
    background-color: $white;
    border-color: $gray-100;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $gray-900;
      background-color: $white;
      border-color: #cdd2df;
    }
  }
}

.btn-light {
  color: $gray-900;
  background-color: $gray-100;
  border-color: #e9e9ff82;

  &:hover {
    color: $gray-900;
    background-color: #dde0e9;
    border-color: #d5d9e4;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
    background-color: #f4f4fb !important;
    border-color: #ededf5 !important;
  }

  &.disabled, &:disabled {
    color: $gray-900;
    background-color: $gray-100;
    border-color: $gray-100;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $gray-900;
      background-color: #d5d9e4;
      border-color: #cdd2df;
    }
  }
}

.show > .btn-light.dropdown-toggle {
  color: $gray-900;
  background-color: #d5d9e4;
  border-color: #cdd2df;
}

.btn-light:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
  }
}

.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
}

.btn-dark {
  color: $white;
  background-color: $gray-800;
  border-color: $gray-800;

  &:hover {
    color: $white;
    background-color: #2d374b;
    border-color: #283143;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $gray-800;
    border-color: $gray-800;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $gray-800;
      border-color: $gray-800;
    }
  }
}

.show > .btn-dark.dropdown-toggle {
  color: $white;
  background-color: $gray-800;
  border-color: $gray-800;
}

.btn-dark:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
  }
}

.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}

.btn-outline-white {
  color: $white !important;;
  border-color: $white;

  &:hover {
    color: $white !important;
    border-color: $white;
  }
}

.btn-outline-primary {
  color: $primary !important;;
  border-color: $primary;

  &:hover {
    color: $white !important;;
    background-color: $primary !important;
    border-color: $primary;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba($primary,0.5);
  }

  &.disabled, &:disabled {
    color: $primary;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white !important;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.show > .btn-outline-primary.dropdown-toggle {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-outline-primary:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary,0.5);
    background: $primary !important;
  }
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
}

.btn-outline-secondary {
  color: $secondary !important;
  border-color: $secondary;

  &:hover {
    color: $white !important;
    background-color: $secondary;
    border-color: $secondary;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 79, 117, 0.5) !important;
  }

  &.disabled, &:disabled {
    color: $gray-600;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $secondary;
      border-color:$secondary;
    }
  }
}

.show > .btn-outline-secondary.dropdown-toggle {
  color: $white;
  background-color: $gray-600;
  border-color: $gray-600;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgb(247, 79, 117);
  }
}

.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 79, 117, 0.5);
}

.btn-outline-success {
  color: $success !important;
  border-color: $success;

  &:hover {
    color: $white !important;
    background-color: $success;
    border-color: $success;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
  }

  &.disabled, &:disabled {
    color: $success;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $success;
      border-color: $success;
    }
  }
}

.show > .btn-outline-success.dropdown-toggle {
  color: $white !important;
  background-color: $success;
  border-color: $success;
}

.btn-outline-success:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
  }
}

.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
}

.btn-outline-info {
  color: $info !important;
  border-color: $info;

  &:hover {
    color: $white !important;
    background-color: $info;
    border-color: $info;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
  }

  &.disabled, &:disabled {
    color: $info;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $info;
      border-color: $info;
    }
  }
}

.show > .btn-outline-info.dropdown-toggle {
  color: $white;
  background-color: $info;
  border-color: $info;
}

.btn-outline-info:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
  }
}

.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.btn-outline-warning {
  color: $warning !important;
  border-color: $warning;

  &:hover {
    color:$white !important;
    background-color: $warning;
    border-color: $warning;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
  }

  &.disabled, &:disabled {
    color: $warning;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $warning;
      border-color: $warning;
    }
  }
}

.show > .btn-outline-warning.dropdown-toggle {
  color: $gray-900 ;
  background-color: $warning;
  border-color: $warning;
}

.btn-outline-warning:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
  }
}

.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
}

.btn-outline-danger {
  color: #e3073a !important;
  border-color: #e3073a;

  &:hover {
    color: $white !important;
    background-color: $danger;
    border-color: $danger;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.5);
  }

  &.disabled, &:disabled {
    color: $danger;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $danger;
      border-color: $danger;
    }
  }
}

.show > .btn-outline-danger.dropdown-toggle {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}

.btn-outline-danger:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.5);
  }
}

.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.5);
}

.btn-outline-light {
  color: $gray-900;
  border-color: $gray-100;

  &:hover {
    color: $gray-900;
    background-color: $gray-100;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
  }

  &.disabled, &:disabled {
    color: $gray-500;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $gray-900;
      background-color: $gray-100;
      border-color: $gray-100;
    }
  }
}

.show > .btn-outline-light.dropdown-toggle {
  color: $gray-900;
  background-color: $gray-100;
  border-color: $gray-100;
}

.btn-outline-light:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(205, 211, 228, 0.5);
  }
}

.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.btn-outline-dark {
  color: $gray-800;
  border-color: $gray-800;

  &:hover {
    color: $white;
    background-color: $gray-800;
    border-color: $gray-800;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
  }

  &.disabled, &:disabled {
    color: $gray-800;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $gray-800;
      border-color: $gray-800;
    }
  }
}

.show > .btn-outline-dark.dropdown-toggle {
  color: $white;
  background-color: $gray-800;
  border-color: $gray-800;
}

.btn-outline-dark:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
  }
}

.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

.btn-link {
  font-weight: 400;
  color: $primary;
  text-decoration: none;

  &:hover {
    color: #8485fb;
    text-decoration: none;
  }

  &:focus, &.focus {
    text-decoration: none;
    box-shadow: none;
  }

  &:disabled, &.disabled {
    color: $gray-600;
    pointer-events: none;
  }
}

.btn-lg, .btn-group-lg > .btn, .sp-container .btn-group-lg > button {
  padding: 0.5rem 1rem !important;
  font-size: 1.02375rem !important;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn, .sp-container .btn-group-sm > button {
  font-size: .725rem !important;
  padding: .28rem .51rem !important;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;

  + .btn-block {
    margin-top: 0.5rem;
  }
}

input {
  &[type="submit"].btn-block, &[type="reset"].btn-block, &[type="button"].btn-block {
    width: 100%;
  }
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn, .sp-container .btn-group > button, .btn-group-vertical > .btn, .sp-container .btn-group-vertical > button {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover, .sp-container .btn-group > button:hover, .btn-group-vertical > .btn:hover, .sp-container .btn-group-vertical > button:hover, .btn-group > .btn:focus, .sp-container .btn-group > button:focus, .btn-group > .btn:active, .sp-container .btn-group > button:active, .btn-group > .btn.active, .sp-container .btn-group > button.active, .btn-group-vertical > .btn:focus, .sp-container .btn-group-vertical > button:focus, .btn-group-vertical > .btn:active, .sp-container .btn-group-vertical > button:active, .btn-group-vertical > .btn.active, .sp-container .btn-group-vertical > button.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .input-group {
    width: auto;
  }
}

.btn-group > .btn:not(:first-child), .sp-container .btn-group > button:not(:first-child) {
  margin-left: -1px;
}

.btn-group > {
  .btn-group:not(:first-child) {
    margin-left: -1px;
  }

  .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.sp-container .btn-group > button:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn, .sp-container .btn-group > .btn-group:not(:last-child) > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .sp-container .btn-group-sm > button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .sp-container .btn-group-lg > button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > .btn {
    width: 100%;
  }
}

.sp-container .btn-group-vertical > button {
  width: 100%;
}

.btn-group-vertical > {
  .btn-group {
    width: 100%;
  }

  .btn:not(:first-child) {
    margin-top: -1px;
  }
}

.sp-container .btn-group-vertical > button:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > {
  .btn-group:not(:first-child) {
    margin-top: -1px;
  }

  .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.sp-container .btn-group-vertical > button:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn, .sp-container .btn-group-vertical > .btn-group:not(:last-child) > button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .sp-container .btn-group-vertical > button:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn, .sp-container .btn-group-vertical > .btn-group:not(:first-child) > button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .sp-container .btn-group-toggle > button, .btn-group-toggle > .btn-group > .btn, .sp-container .btn-group-toggle > .btn-group > button {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .sp-container .btn-group-toggle > button input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .sp-container .btn-group-toggle > button input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .sp-container .btn-group-toggle > .btn-group > button input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .sp-container .btn-group-toggle > .btn-group > button input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

/* ###### 3.3 Buttons ###### */

// .btn, .sp-container button {
//   font-size: 0.8125rem;
// }

.btn:active, .sp-container button:active, .btn:focus, .sp-container button:focus {
  box-shadow: none;
}

.show > .btn-light.dropdown-toggle {
  color: $gray-900;
  background-color: $border;
  border-color: $border;
} 

.btn-light:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgb(240, 239, 244);
  }
}

.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(240, 239, 244);
}

.btn-indigo {
  color: $white;
  background-color: #b057bf;
  border-color: #b057bf;

  &:hover {
    color: $white;
    background-color: #d556b7;
    border-color: #d556b7;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: #b057bf;
    border-color: #b057bf;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: #b057bf;
      border-color: #b057bf;
    }
  }
}

.show > .btn-indigo.dropdown-toggle {
  color: $white;
  background-color: #b057bf;
  border-color: #b057bf;
}

.btn-indigo:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
  }
}

.show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
}

.btn-purple {
  color: $white;
  background-color: $purple;
  border-color: #643ab0;

  &:hover {
    color: $white;
    background-color: #5e37a6;
    border-color: #4e2d89;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $purple;
    border-color: #643ab0;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: #59339d;
      border-color: #482a80;
    }
  }
}

.show > .btn-purple.dropdown-toggle {
  color: $white;
  background-color: #59339d;
  border-color: #482a80;
}

.btn-purple:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
  }
}

.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
}

.btn-pink {
  color: $white;
  background-color: $pink;
  border-color: #cc30ce;

  &:hover {
    color: $white;
    background-color: #cc30ce;
    border-color: #cc30ce;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $pink;
    border-color: #d80069;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: #be005c;
      border-color: #98004a;
    }
  }
}

.show > .btn-pink.dropdown-toggle {
  color: $white;
  background-color: #be005c;
  border-color: #98004a;
}

.btn-pink:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
  }
}

.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.btn-gray-500 {
  color: $gray-900;
  background-color: $gray-500;
  border-color: #8896af;

  &:hover {
    color: $white;
    background-color: #808faa;
    border-color: #697b9a;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
  }

  &.disabled, &:disabled {
    color: $gray-900;
    background-color: $gray-500;
    border-color: #8896af;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: #7988a4;
      border-color: #637493;
    }
  }
}

.show > .btn-gray-500.dropdown-toggle {
  color: $white;
  background-color: #7988a4;
  border-color: #637493;
}

.btn-gray-500:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
  }
}

.show > .btn-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.btn-gray-700 {
  color: $white;
  background-color: $gray-700;
  border-color: #4f5c73;

  &:hover {
    color: $white;
    background-color: #49566b;
    border-color: #3a4455;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $gray-700;
    border-color: #4f5c73;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: #445064;
      border-color: #353e4d;
    }
  }
}

.show > .btn-gray-700.dropdown-toggle {
  color: $white;
  background-color: #445064;
  border-color: #353e4d;
}

.btn-gray-700:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
  }
}

.show > .btn-gray-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}


.btn-outline-indigo {
  border-color: #b057bf;
  color: #b057bf;

  &:hover, &:focus {
    background-color: #b057bf;
  }
}


/* ###### 5.1 Buttons  ###### */

.btn-main-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary;

  &:hover {
    color: $white;
    background-color:#6056f5  !important;
    border-color: #8077f6 ;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.15rem rgba($primary, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $primary!important;
    border-color: $primary  !important;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow:0 0 0 0.2rem $primary-05;
  }
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba($primary, 0.2);
}

.btn-main-secondary {
  color: $white;
  background-color: $primary;
  border-color: $primary;

  &:hover {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 210, 191, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.show > .btn-main-secondary.dropdown-toggle {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-main-secondary:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
  }
}

.show > .btn-main-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
}

.btn-main-secondary {
  color: $white;
  background-color: $primary;
  border-color: #1a53ff;

  &:hover {
    color: $white;
    background-color: #0d49ff;
    border-color: #8485fb;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $primary;
    border-color: #1a53ff;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: #0040ff;
      border-color: #0036d9;
    }
  }
}

.show > .btn-main-secondary.dropdown-toggle {
  color: $white;
  background-color: #0040ff;
  border-color: #0036d9;
}

.btn-main-secondary:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
  }
}

.show > .btn-main-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
}

.btn-rounded {
  border-radius: 50px;
}

.btn-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;

  i {
    line-height: 0;
    font-size: 20px;
    margin-right: 5px;

    &.typcn {
      line-height: .6;
    }

    &.fas {
      font-size: 15px;
    }

    &.icon {
      font-size: 18px;
    }
  }
}

.btn-icon {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  i {
    line-height: 0;
    font-size: 1rem;

    &.typcn {
      line-height: 1.95;
    }
  }
}

.btn-icon-list {
  display: flex;
  align-items: center;

  .btn + .btn, .sp-container button + .btn {
    margin-left: 5px;
  }
}

.sp-container .btn-icon-list button + .btn, .btn-icon-list .sp-container .btn + button, .sp-container .btn-icon-list .btn + button, .btn-icon-list .sp-container button + button, .sp-container .btn-icon-list button + button {
  margin-left: 5px;
}

@media (max-width: 991px) {
  .btn.btn-default.nav-link {
    margin-top: 0;
  }
}
.nav-link#bs-example-navbar-collapse-1 {
    padding: 0rem 0.3rem;
}
/* ###### 4.8 button-light ###### */

.btn-warning-light {
  color: #FDAC41;
  background-color: rgba(253, 172, 65, 0.2);
  border-color: rgba(253, 172, 65, 0.2);

  &:hover {
    color: $white;
    background-color: #FDAC41;
    border-color: #FDAC41;
  }
}


.btn-warning-light {
  &.disabled, &:disabled {
    color: $gray-900;
    background-color: #FDAC41;
    border-color: #FDAC41;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $gray-900;
      background-color: #FDAC41;
      border-color: #FDAC41;
    }
  }
}

.show > .btn-warning-light.dropdown-toggle {
  color: $gray-900;
  background-color: #FDAC41;
  border-color: #FDAC41;
}

.btn-warning-light:not(:disabled):not(.disabled) {
  &:active:focus, &.active:focus {
    box-shadow: 0 0 0 0.2rem #ffc793;
  }
}

.show > .btn-warning-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #ffc793;
}


.btn-primary-gradient{
	color: $white;
	background:$primary-gradient;
	border:1px solid $primary;
    &:hover {
      color: $white !important;
    }
}


.btn-danger-gradient{
	color: $white;
	background:$secondary-gradient;
	border:1px solid $danger;
  &:hover {
    color: $white !important;
  }
}


.btn-pink-gradient{
	color: $white;
	background:$pink-gradient;
	border:1px solid $pink;
  &:hover {
    color: $white !important;
  }
}


.btn-purple-gradient{
	color: $white;
	background:$purple-gradient;
	border:1px solid $purple;
  &:hover {
    color: $white !important;
  }
}

.btn-warning-gradient{
	color: $white;
	background: $warning-gradient;
	border:1px solid $warning;
  &:hover {
    color: $white !important;
  }
}


.btn-success-gradient{
	color: $white;
	background: $success-gradient;
	border:1px solid $success;
  &:hover {
    color: $white !important;
  }
}


.btn-info-gradient{
	color: $white;
	background: $info-gradient;
	border:1px solid $info;
  &:hover {
    color: $white !important;
  }
}


.btn-light-gradient{
	color: $black;
	background: linear-gradient(120deg, #cdd2de 0%, $gray-100 100%);
	border:1px solid #cdd2de;
	
	&:hover {
    color: $black;
    background: linear-gradient(120deg, #c1c7d6 0%, #e0e5f1 100%);
    border-color: #cdd2de;
  }
}

.btn-light-gradient{
  &:focus, &.focus {
	 background: linear-gradient(120deg, #c1c7d6 0%, #e0e5f1 100%);
  }
}

.btn-dark-gradient{
	color: $white;
	background: linear-gradient(60deg, #29323c 0%, #485563 100%);
	border:1px solid #29323c;
	
	&:hover {
    color: $white;
    background: linear-gradient(60deg, #212931 0%, #424f5d 100%);
    border-color: #29323c;
  }
}

.btn-dark-gradient{
  &:focus, &.focus {
	 background:linear-gradient(60deg, #212931 0%, #424f5d 100%);
  }
}


.btn-secondary-gradient{
	color: $white;
	background: $secondary-gradient;
    border: 1px solid $secondary;
    &:hover {
      color: $white !important;
    }
}

.btn-primary.focus, .btn-primary:focus{
 color: $white;
    background-color: $primary;
    border-color: $primary;

}
.btn-warning.focus, .btn-warning:focus {
    color: $white;
    background-color: $warning;
    border-color: $warning;
}
.btn-square {
    border-radius: 0;
}
.btn-list>.btn:not(:last-child), .btn-list>.dropdown:not(:last-child) {
    margin-right: .4rem;
}
.btn-list>.btn, .btn-list>.dropdown {
    margin-bottom: .3rem;
}
.row>* {
 width: auto;

}
.btn-outline-light:hover {
    border-color: $border;
}
.btn-check:focus+.btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color:$secondary;
    border-color:$secondary;
	}
.nav.panel-tabs  .nav-item:focus-visible{
	outline:none !important;
	}
	
	.tabs-style-1 .main-nav-line .nav-link.active {
    /* color: #242f48; */
    border: 1px solid;
    border-color: $border $border #fff;
}
.btn-animation .btn-loaders:after {
    content: '';
    -webkit-animation: loader 500ms infinite linear;
    animation: loader 500ms infinite linear;
    border: 2px solid #fff;
    border-radius: 50%;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    display: block;
    height: 1.4em;
    width: 1.4em;
    position: absolute;
    right: 10px !important;
    top: calc(50% - (1.4em / 2));
    -webkit-transform-origin: center;
    transform-origin: center;
    position: absolute !important;
}
.btn-animation .btn-spiner:after {
    content:"";
    -webkit-animation: sk-bounce 1.2s infinite ease-in-out;
    animation: sk-bounce 1.2s infinite ease-in-out;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
    border-radius: 50%;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    display: block;
    height: 0.5em;
    width: 0.5em;
    position: absolute;
    right: 12px !important;
    top: calc(50% - (1.4em / 2));
   -webkit-transform-origin: center; 
    transform-origin: center;
    position: absolute !important;
     background: #ffffff;
    width: 14%;
    height: 45%;
}
.btn-animation .btn-loaders {
    color: #fff !important;
    pointer-events: none;
    position: relative;
}

.btn-animation .btn-spiner {
    color: #fff !important;
    pointer-events: none;
    position: relative;
}
.btn-animation .loading {
    margin-right: 30px;
}

@-webkit-keyframes loader {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.btn-primary-light {
    color: $primary;
    background-color: $primary-02;
    border-color: $primary-02;
  &:hover {
    color: $white;
    background-color:$primary  !important;
    border-color: $primary ;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem $primary-02;
  }
}


.btn-secondary-light {
   color: #f74f75;
    background-color: rgba(247, 79, 117,0.2);
    border-color: rgba(247, 79, 117, 0.2);
	
  &:hover {
    color: $white;
    background-color:$secondary  !important;
    border-color: $secondary ;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 79, 117, 0.2) ;
  }
}
.btn-success-light {
	color: #1a9c86 !important;
	background-color: rgba(26, 156, 134, 0.2);
	border-color: rgba(26, 156, 134, 0.2);
	
	 &:hover {
    color: $white !important;
    background-color:$success  !important;
    border-color: $success ;
  }

  &:focus, &.focus {
    box-shadow:0 0 0 0.2rem rgba(26, 156, 134, .2);
  }
}
.btn-danger-light {
    color: #f34343 !important;
    background-color: rgba(243, 67, 67, .2);
    border-color: rgba(243, 67, 67, 0.2);
	
	 &:hover {
    color: $white !important;
    background-color:$danger  !important;
    border-color: $danger ;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, .2);
  }
}
.btn-info-light {
    color: #4ec2f0;
    background-color: rgba(25, 192, 192, 0.2);
    border-color: rgba(25, 192, 192, 0.2);
	
	 &:hover {
    color: $white !important;
    background-color:$info  !important;
    border-color: $info ;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 192, 192, .2);
  }
}

.btn-dark-light {
    color: #383853;
    background-color: #38385324;
    border-color: #c8c8da;
	
	 &:hover {
    color: $white !important;
    background-color:$dark  !important;
    border-color: rgba(200, 200, 218, 0.2) ;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, .2);
  }
}
.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
    color: #fff !important;
    background-color:  $primary;
    border-color:  $primary;
    z-index: 0 !important;
}
.btn-check:active+.btn-outline-primary:focus, .btn-check:checked+.btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus{
  box-shadow: 0 0 0 0.2rem rgba($primary,0.5) !important;
}
.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus {
   box-shadow: none !important;
}
.btn-check:active+.btn-outline-warning, .btn-check:checked+.btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:active {
    color: #fff !important;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-check:active+.btn-outline-info, .btn-check:checked+.btn-outline-info, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show, .btn-outline-info:active {
    color: #fff !important;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
}
.btn-check:active+.btn-outline-danger, .btn-check:checked+.btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show, .btn-outline-danger:active {
    color: #fff !important;
    background-color: #dc3545;
    border-color: #dc3545;
}